import PropTypes from 'prop-types';
import React from 'react';

import InputRange from 'react-input-range';

import Loader from '../Loader';

export default class OpacitySlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      min: 50,
      max: 90,
      temporaryValue: props.preset.overlayOpacity,
    };

    this.loaderRef = React.createRef();
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.state.temporaryValue = Number(e)

    if (e<50) {
      e = 50
    } else if (e>90) {
      e = 90
    }

    var opacityData = {
      overlayOpacity: e
    }

    this.loaderRef.current.start(this.props.actions.updateUiPreset(opacityData))
  }

  componentDidUpdate(prevProps) {
    if(prevProps.preset.overlayOpacity != this.props.preset.overlayOpacity) {
      this.setState({ temporaryValue: this.props.preset.overlayOpacity });
    }
  }

  render() {
    return (
      <div className="opacity-slider">
        <input
          className="form-control slider-value d-inline-block mb-4 mt-0"
          type="number"
          min={this.state.min}
          max={this.state.max}
          value={this.state.temporaryValue}
          onChange={event => this.handleChange(event.target.value)} />
        <InputRange
          maxValue={this.state.max}
          minValue={this.state.min}
          value={this.state.temporaryValue}
          onChange={value => {this.setState({ temporaryValue: value })}}
          onChangeComplete={value => {this.handleChange(value)}} />
        <Loader ref={this.loaderRef}/>
      </div>
    )
  }
}
