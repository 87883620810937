import { useState, useCallback, useEffect } from 'react';
import { DateUtils } from 'react-day-picker';
import moment from 'moment';

const useMultiDatesPicker = ({ onChange, initialValue }) => {
  const [selectedDays, _setSelectedDays] = useState([]);

  useEffect(() => {
    _setSelectedDays(initialValue.map(date => moment(date).toDate()));
  }, [initialValue]);

  const setSelectedDays = _selectedDays => {
    _setSelectedDays(_selectedDays);
    onChange(_selectedDays.map(date => moment(date).format()));
  };

  const removeRangeByIndex = useCallback((index, _selectedDays) => {
    const newSelectedDays = [..._selectedDays];
    newSelectedDays.splice(index, 1);
    setSelectedDays(newSelectedDays);
  }, []);

  const handleDayClick = useCallback((day, selected, _selectedDays) => {
    const newSelectedDays = [..._selectedDays];
    if (selected) {
      const selectedIndex = newSelectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      newSelectedDays.splice(selectedIndex, 1);
    } else {
      newSelectedDays.push(day);
    }
    newSelectedDays.sort((a, b) => a - b);
    setSelectedDays(newSelectedDays);
  }, []);

  return {
    selectedDays,
    handleDayClick,
    removeRangeByIndex,
    _setSelectedDays
  };
};

export default useMultiDatesPicker;
