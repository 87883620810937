import PropTypes from 'prop-types';
import React from 'react';
import Axios from 'axios';

const Uppy = require('@uppy/core');
const ActiveStorageUpload = require('uppy-activestorage-upload');
const toArray = require('@uppy/utils/lib/toArray');

export default class Uploader extends React.Component {
  static defaultProps = {
    showIcon: true,
    text: 'Upload',
    debug: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
    };

    this.handleDispatchSave = this.handleDispatchSave.bind(this);
    this.handleDispatchError = this.handleDispatchError.bind(this);
  }

  handleDispatchSave(file, resp) {
    return this.props.dispatchSave(file, resp);
  }

  handleDispatchError(resp) {
    return this.props.dispatchError(resp);
  }

  componentDidMount() {
    this.uppy = Uppy({
      debug: this.props.debug,
      restrictions: this.props.restrictions,
    })
      .use(ActiveStorageUpload, {
        directUploadUrl: this.props.directUploadPath,
      })
      .on('file-added', file => {
        if (this.props.dispatchError) {
          this.handleDispatchError(false);
        }

        this.uppy.upload();
        this.setState({
          progress: 1,
        });
      })
      .on('upload-progress', (file, progress) => {
        const p = (progress.bytesUploaded / progress.bytesTotal) * 100;
        if (p < 100) {
          this.setState({
            progress: p,
          });
        }
      })
      .on('upload-success', (file, resp) => {
        this.setState({
          progress: 99,
        });

        if (this.props.saveUploadPath && !this.props.dispatchSave) {
          var data = {};
          data[this.props.objectName] = {};
          data[this.props.objectName][this.props.attributeName] =
            resp.signed_id;

          Axios.put(this.props.saveUploadPath, data)
            .then(response => {
              this.setState({
                progress: 100,
              });
              setTimeout(this.reset, 4000);

              if (
                response.headers['content-type'].match(
                  /\b(?:java|ecma)script\b/,
                )
              ) {
                var script = document.createElement('script');
                script.text = response.data;
                document.head
                  .appendChild(script)
                  .parentNode.removeChild(script);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          this.handleDispatchSave(file, resp)
            .then(response => {
              this.setState({
                progress: 100,
              });
              setTimeout(this.reset, 4000);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  handleInputChange = ev => {
    const files = toArray(ev.target.files);

    files.forEach(file => {
      try {
        this.uppy.addFile({
          source: 'Local',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (err) {
        if (this.props.dispatchError) {
          this.handleDispatchError(err.message);
        }
        console.log(err);
      }
    });
  };

  reset = () => {
    this.setState({
      progress: 0,
    });
  };

  text = () => {
    if (this.state.progress == 0) {
      return this.props.text;
    } else if (this.state.progress < 100) {
      return `${Math.round(this.state.progress)}%`;
    } else {
      return 'Done!';
    }
  };

  render() {
    return (
      <div className="file-selector">
        <input
          id={this.props.inputId}
          type="file"
          name={`${this.props.objectName}[${this.props.attributeName}]`}
          onChange={this.handleInputChange}
        />
        <label
          className="btn btn-outline-dark btn-upload"
          htmlFor={this.state.progress > 0 ? '' : this.props.inputId}
        >
          {this.props.showIcon && <i className="fas fa-upload" />}
          {this.text()}
        </label>
      </div>
    );
  }
}

Uploader.propTypes = {
  objectName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  directUploadPath: PropTypes.string.isRequired,
  saveUploadPath: PropTypes.string,
  showIcon: PropTypes.bool,
  text: PropTypes.string,
  debug: PropTypes.bool,
  dispatchSave: PropTypes.func,
  dispatchError: PropTypes.func,
  restrictions: PropTypes.object,
};
