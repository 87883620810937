import { Controller } from 'stimulus';
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = [
    'demoFeeRevenue',
    'handlingRevenue',
    'purchases',
    'deposits',
    'refunds',
  ];

  get financeMetrics() {
    return JSON.parse(this.data.get('list'));
  }

  get currencySymbol() {
    return this.data.get('currencySymbol');
  }

  counter(target, number) {
    const options = {
      duration: 2,
      decimalPlaces: 2,
      prefix: this.currencySymbol,
    };
    var counter = new CountUp(target, number, options);
    counter.start();
  }

  initialize() {
    this.counter(
      this.demoFeeRevenueTarget,
      this.financeMetrics['demo_fee_revenue'],
    );
    this.counter(
      this.handlingRevenueTarget,
      this.financeMetrics['handling_revenue'],
    );
    this.counter(this.purchasesTarget, this.financeMetrics['purchases']);
    this.counter(this.depositsTarget, this.financeMetrics['deposits']);
    this.counter(this.refundsTarget, this.financeMetrics['refunded']);
  }
}
