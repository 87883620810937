import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["map"];

  initialize() {
  }

  connect() {
    this.initMap();
  }

  get points() {
    return JSON.parse(this.data.get("points"))
  }
  set points(value) {
    this.data.set("points", JSON.stringify(value))
  }

  getPoints() {
    var arrayOfPoints = [];
    for (let latlng of this.points) {
      arrayOfPoints.push(new google.maps.LatLng(latlng[0], latlng[1]));
    }
    return arrayOfPoints;
  }

  initMap() {
    const mapInitializer = () => {
      var bounds = new google.maps.LatLngBounds();
      var map = new google.maps.Map(this.mapTarget, {
        mapTypeId: 'terrain',
        styles:
          [
              {
                  "featureType": "administrative",
                  "elementType": "labels.text.fill",
                  "stylers": [
                      {
                          "color": "#444444"
                      }
                  ]
              },
              {
                  "featureType": "landscape",
                  "elementType": "all",
                  "stylers": [
                      {
                          "color": "#f2f2f2"
                      }
                  ]
              },
              {
                  "featureType": "poi",
                  "elementType": "all",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "road",
                  "elementType": "all",
                  "stylers": [
                      {
                          "saturation": -100
                      },
                      {
                          "lightness": 45
                      }
                  ]
              },
              {
                  "featureType": "road.highway",
                  "elementType": "all",
                  "stylers": [
                      {
                          "visibility": "simplified"
                      }
                  ]
              },
              {
                  "featureType": "road.arterial",
                  "elementType": "labels.icon",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "transit",
                  "elementType": "all",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "water",
                  "elementType": "all",
                  "stylers": [
                      {
                          "color": "#46bcec"
                      },
                      {
                          "visibility": "on"
                      }
                  ]
              }
          ],
      });
      for (let point of this.getPoints()) {
        bounds.extend(point);
      }
      map.fitBounds(bounds);

      if (this.getPoints().length === 1) var zoom = 12
      if (this.getPoints().length === 0) var zoom = 2

      var listener = google.maps.event.addListener(map, "idle", function() {
        if (map.getZoom() > zoom) {
          map.setZoom(zoom);
          if (zoom === 2) map.setCenter( {lat: 47.422958, lng: -40.985718} )
        }
        google.maps.event.removeListener(listener);
      });

      var heatmap = new google.maps.visualization.HeatmapLayer({
        data: this.getPoints(),
        map: map,
        radius: 20
      });
    };

    window.mapInitializers.push(mapInitializer);
  }

}
