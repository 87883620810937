import React from 'react';
import InlineEditor from '../InlineEditor';
import PropTypes from 'prop-types';

export default class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: props.id,
        excludeFromFeedback: props.excludeFromFeedback,
      },
    };
  }

  handleSubmit = () => {
    var data = {
      excludeFromFeedback: this.refs.excludeFromFeedback.checked,
    };

    this.refs.inlineEditor.put(
      `/api/admin/v1/bookings/${this.state.booking.id}`,
      data,
      () => {
        this.setState((prevState, props) => ({
          booking: { ...prevState.booking, ...data },
        }));
      },
    );
  };

  viewer = () => {
    return (
      <span className="editor-wrapper">
        <div className="form-check">
          <label>
            <input
              className="form-check-input"
              type="checkbox"
              checked={this.state.booking.excludeFromFeedback}
              onChange={this.handleSubmit}
              ref="excludeFromFeedback"
            />
            Exclude from feedback
          </label>
        </div>
      </span>
    );
  };

  editor = () => {};

  render() {
    return (
      <InlineEditor
        editor={this.editor()}
        viewer={this.viewer()}
        ref="inlineEditor"
      />
    )
  }
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  excludeFromFeedback: PropTypes.bool,
};
