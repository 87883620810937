import { connect } from 'react-redux';
import PurchasingWizard from '../components/AdminPanel/Purchasings/PurchasingWizard';
import * as actions from '../actions/admin/program/purchaseActionCreators';

export const mapStateToProps = (state) => {
  var newState = {...state.purchaseUiPreset};

  return newState;
}

const mapDispatchToProps = (dispatch) => {
  var props = {actions: {}};

  props.actions.updatePurchaseUiPreset = (payload) => {
    return dispatch(actions.updatePurchaseUiPreset(payload));
  }

  props.actions.updateProgramPurchase = (payload) => {
    return dispatch(actions.updateProgramPurchase(payload));
  }

  props.actions.purgeAttachment = (payload) => {
    return dispatch(actions.purgeAttachment(payload));
  }

  return props;
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingWizard);
