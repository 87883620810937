import PropTypes from 'prop-types';
import React from 'react';

export default class Loader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.startRef = React.createRef();
  }

  start = (promise) => {
    const node = this.startRef.current
    node.className = "fa fa-spinner fa-pulse"

    promise.then((data) => {
      node.className = "fa fa-check-circle text-success"
    }).catch((data) => {
      node.className = "fa fa-times-circle text-danger"
      window.alert(data)
    });
  }

  render() {
    return (
      <i ref={this.startRef}></i>
    )
  }
}
