/* eslint-disable import/prefer-default-export */

import Axios from 'axios';
import humps from 'humps';

import { ADMIN_PROGRAM_UI_PRESET_UPDATE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_PUBLISH } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_SWITCH } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_CREATE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_CLONE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_DELETE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_ACTIVE_STORAGE_ATTACHMENT_DELETE } from '../../../constants/admin/program/uiPresetConstants';

import { mapStateToProps as mapProgramStateToProps } from '../../../containers/ProgramCustomisationContainer';

export const updateUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_UI_PRESET_UPDATE,
  payload,
});

export const publishUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_UI_PRESET_PUBLISH,
  payload,
});

export const switchUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_UI_PRESET_SWITCH,
  payload,
});

export const createUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_UI_PRESET_CREATE,
  payload,
});

export const cloneUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_UI_PRESET_CLONE,
  payload,
});

export const deleteUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_UI_PRESET_DELETE,
  payload,
});

export const purgeAttachmentAction = (payload) => ({
  type: ADMIN_ACTIVE_STORAGE_ATTACHMENT_DELETE,
  payload,
})

export const publishUiPreset = (presetId) => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    return Axios({
      method: 'put',
      url: `/api/admin/v1/programs/${state.presets.current().programId}/ui_presets/${presetId}/publish`,
      responseType: 'json',
      params: presetId,
    }).then(response => {
      dispatch(publishUiPresetAction(response.config.params));
      return response;
    })
  }
}

export const switchUiPreset = (presetId) => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    return Axios({
      method: 'get',
      url: `/api/admin/v1/programs/${state.presets.current().programId}/ui_presets/${presetId}`,
      responseType: 'json',
      params: presetId,
    }).then(response => {
      dispatch(switchUiPresetAction(response.config.params));
      return response;
    })
  }
}

export const updateUiPreset = (data) => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    if (state.presets.current().published) {
      return cloneUiPreset(data)(dispatch, getState);
    } else {
      return Axios({
        method: 'put',
        url: `/api/admin/v1/programs/${state.presets.current().programId}/ui_presets/${state.presets.current().id}`,
        responseType: 'json',
        data: humps.decamelizeKeys(data),
      }).then(response => {
        if (Object.keys(data)[0].includes("Text")) {
          const key = Object.keys(data)[0].replace("Text", "")
          var newData = { texts: {} }
          newData.texts[key] = response.data.ui_preset.texts[key]
        } else {
          const key = Object.keys(data)[0]
          var newData = {}
          newData[key] = response.data.ui_preset[key]
        }
        dispatch(updateUiPresetAction(newData));
        return response;
      });
    }
  };
}

export const cloneUiPreset = (data = {}) => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());
    data["copyFromId"] = state.currentPresetId

    return Axios({
      method: 'post',
      url: `/api/admin/v1/programs/${state.presets.current().programId}/ui_presets/`,
      responseType: 'json',
      data: humps.decamelizeKeys(data),
    }).then(response => {
      dispatch(createUiPresetAction(response.data.ui_preset));
      return response;
    });
  }
}

export const newUiPreset = () => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    return Axios({
      method: 'post',
      url: `/api/admin/v1/programs/${state.presets.current().programId}/ui_presets/`,
      responseType: 'json'
    }).then(response => {
      dispatch(createUiPresetAction(response.data.ui_preset));
      return response;
    });
  }
}

export const deleteUiPreset = (data) => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());
    return Axios({
      method: 'delete',
      url: `/api/admin/v1/programs/${state.presets.current().programId}/ui_presets/${data.id}`,
      responseType: 'json',
      params: data,
    }).then(response => {
      dispatch(deleteUiPresetAction(response));
      return response;
    });
  }
}

export const purgeAttachment = (data) => {
  return function (dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    if (state.presets.current().published) {
      var attachment = {}
      attachment["ui_preset"] = {}
      attachment["ui_preset"][data.name] = "_destroy"

      return cloneUiPreset(attachment)(dispatch, getState);
    }
    return Axios({
      method: 'delete',
      url: `/api/admin/v1/active_storage/attachments/${data.id}`,
      responseType: 'json',
      params: data,
    }).then(response => {
      dispatch(purgeAttachmentAction(response));
      return response;
    })
  }
}
