import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button'];

  get time() {
    return this.data.get('time');
  }

  showMessage(el, time) {
    $(el).on('ajax:success', function (e, data) {
      if (data.status == 'ok') {
        e.target.style.display = 'none';
        if (e.target.parentNode) {
          e.target.parentNode.innerHTML =
            '<p id="login-outcome" class="success">' + data.message + '</p>';
        }
        setTimeout(function () {
          window.location.href = data.redirect;
        }, time);
      } else {
        if ($('#login-outcome').length > 0) {
          $('#login-outcome').html(data.message);
        } else {
          $(e.target)
            .parent()
            .prepend('<p id="login-outcome">' + data.message + '</p>');
        }
      }
    });
  }

  buttonSubmit() {
    this.showMessage(this.buttonTarget.parentElement, this.time);
  }
}
