import PropTypes from 'prop-types';
import React from 'react';
import reactCSS from 'reactcss';
import { ChromePicker } from 'react-color';
import { CustomPicker } from 'react-color';
import { EditableInput } from 'react-color';
import tinycolor from 'tinycolor2';

import Loader from './Loader';

export default class ColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: this.props.primaryColor,
    };

    this.handleDispatchColor = this.handleDispatchColor.bind(this)
    this.loaderRef = React.createRef();
  }

  getColor() {
    if (this.props.preset) {
      return tinycolor(this.props.preset.primaryColor)
    } else if (this.props.primaryColor) {
      return tinycolor(this.state.color)
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange = (color) => {
    var colorData = {}

    if (typeof color == "string") {
      colorData["primaryColor"] = tinycolor(color).toHexString()
    } else {
      colorData["primaryColor"] = color.hex
    }

    var data = {
      primaryColor: colorData.primaryColor
    }

    if (this.props.actions) {
      this.loaderRef.current.start(this.props.actions.updateUiPreset(data))
    } else if (this.props.dispatchColor) {
      this.setState({color: colorData.primaryColor})
      this.handleDispatchColor(colorData.primaryColor)
    }
  };

  handleDispatchColor(color) {
    return this.props.dispatchColor(color)
  }

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          background: `${ this.getColor() }`,
        },
      },
    });

    var { EditableInput } = require('react-color/lib/components/common');

    return (
      <div className="customisation-container__color-picker">
        <div className = "d-inline-block align-top">
          <EditableInput
            value={ this.getColor() }
            label="hex"
            onChange={this.handleChange}
            />
        </div>
        <div className="color-picker__swatch" onClick={ this.handleClick }>
          <div className="color-picker__color" style={ styles.color } />
        </div>
        <Loader ref={this.loaderRef}/>
        { this.state.displayColorPicker ? <div className="color-picker__popover">
          <div className="color-picker__cover" onClick={ this.handleClose }/>
          <ChromePicker
            color={ this.getColor() }
            onChangeComplete={ this.handleChange }
            disableAlpha={true}
            />
        </div> : null }
      </div>
    )
  }
}
