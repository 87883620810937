import PropTypes from 'prop-types';
import React from 'react';

import ColorPicker from './ColorPicker';

export default class ColorPickerFormWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      color: this.props.primaryColor
    };

    this.dispatchColor = this.dispatchColor.bind(this)
  }

  dispatchColor = (c) => {
    this.setState({ color: c })
  }

  render() {
    return(
      <div>
        <ColorPicker
          {...this.props}

          dispatchColor={this.dispatchColor}
          />
        <input className="string optional hidden" type="text" value={this.state.color} name="brand[primary_color]" id="brand_primary_color" readOnly />
      </div>
    );
  }
}
