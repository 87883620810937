import { Controller } from 'stimulus';
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = ['counter'];

  get number() {
    return JSON.parse(this.data.get('number')).toFixed(2);
  }
  set number(value) {
    this.data.set('number', JSON.stringify(value));
  }

  get currencySymbol() {
    return this.data.get('currencySymbol');
  }
  set currencySymbol(value) {
    this.data.set('currencySymbol');
  }

  initialize() {
    const options = {
      duration: 2,
      decimalPlaces: 2,
      prefix: this.currencySymbol,
    };
    var counter = new CountUp(this.counterTarget, this.number, options);
    counter.start();
  }

  connect() {}
}
