import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from "react-intl";

import Thumbnail from '../Thumbnail';
import DemoUnitPriceInput from './DemoUnitPriceInput';
import NewUnitPriceInput from './NewUnitPriceInput';

export default class PurchasingWizard extends React.Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = {};
  }

  render() {
    return(
      <div className="purchasing-container">
        <div className="card card-body">
          <div className="row">
            <div className="col-12">
              <h5><strong>Purchasing</strong></h5>
              <div className="row customisation-container__section">
                <div className="col-sm-3">
                  <p className="section__subtitle">Product Image</p>
                  <p className="small text-secondary">Product image placed at top of purchase page. A transparent 500 x 500 px image (recommended).</p>
                </div>
                <div className="col-sm-4">
                  <Thumbnail
                    {...this.props}
                    saveAction={this.props.actions.updatePurchaseUiPreset}
                    name="productImage"
                    className="product"
                  />
                </div>
              </div>
              <h5 className="mt-4"><strong>Customer Purchase Options</strong></h5>
              <div className="row customisation-container__section">
                <div className="col-sm-3">
                  <p className="section__subtitle">{`Keep ${this.props.mode.charAt(0).toUpperCase() + this.props.mode.slice(1)} Unit`}</p>
                  <p className="small text-secondary">Allow the customer to <b>{`purchase and keep their current ${this.props.mode} unit.`}</b></p>
                </div>
                <div className="col-sm-3">
                  <DemoUnitPriceInput
                    {...this.props}
                    name="demoUnitKeepPrice"
                    checked={this.props.preset.enableDemoUnitKeepPrice}
                    id="purchaseCheckbox1"
                    price={this.props.demoUnitKeepPrice}
                    text={[`Price of ${this.props.mode} unit, `, <b className="font-italic" key="1">including all local taxes.</b>]}
                    />
                </div>
              </div>
              <div className="row customisation-container__section">
                <div className="col-sm-3">
                  <p className="section__subtitle">Purchase Brand New Unit</p>
                  <p className="small text-secondary">Allow the customer to <b>purchase a brand new unit</b>{` that is shipped to them after returning the ${this.props.mode}.`}</p>
                </div>
                <div className="col-sm-3">
                  <NewUnitPriceInput
                    {...this.props}
                    name="newUnitPurchasePrice"
                    checked={this.props.preset.enableNewUnitPurchasePrice}
                    afterDemo={this.props.preset.enableAfterDemo}
                    id="purchaseCheckbox2"
                    price={this.props.newUnitPurchasePrice}
                    text={["Price of new unit, ", <b className="font-italic" key="2">including all local taxes and shipping.</b>]}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
