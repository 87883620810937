import PropTypes from 'prop-types';
import React from 'react';
import InlineEditor from '../InlineEditor';

export default class CustomerNameEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: {
        id: props.id,
        firstName: props.firstName,
        lastName: props.lastName
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    var data = {
      firstName: this.refs.firstName.value,
      lastName: this.refs.lastName.value
    };

    this.refs.inlineEditor.put(
      `/api/admin/v1/customers/${this.state.customer.id}`,
      data,
      (() => {
        this.setState((prevState, props) => ({
          customer: {...prevState.customer, ...data}
        }));
      })
    );
  }

  viewer = () => {
    return (
      <span className="editor-wrapper">
        <strong>
          {this.state.customer.firstName} {this.state.customer.lastName}
        </strong>
        <button type="button" style={{border: "none", background: "none"}} onClick={() => {this.refs.inlineEditor.edit()}}>
          <span className="fa fa-pencil" style={{fontSize: "14px"}}></span>
        </button>
      </span>
    )
  }

  editor = () => {
    return (
      <form className="form-inline" onSubmit={this.handleSubmit}>
        <div className="input-group input-group-sm">
          <input type="text" placeholder="First Name" className="form-control" defaultValue={this.state.customer.firstName} ref="firstName" />
          <input type="text" placeholder="Last Name" className="form-control" defaultValue={this.state.customer.lastName} ref="lastName" />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary" value="Submit">
              <i className="fa fa-check"></i>
            </button>
            <button type="button" className="btn btn-link" value="Cancel" onClick={() => {this.refs.inlineEditor.show()}} >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <InlineEditor
        editor={this.editor()}
        viewer={this.viewer()}
        ref="inlineEditor"
      />
    );
  }
}
