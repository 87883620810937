import PropTypes from 'prop-types';
import React from 'react';
import Axios from 'axios';

export default class StateDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: props.id,
        state: props.state,
        nextState: props.availableStates[props.state].next,
      },
    };
  }

  get nextState() {
    return this.props.availableStates[this.state.booking.state].next;
  }

  get color() {
    return this.props.availableStates[this.state.booking.state].color;
  }

  get label() {
    return this.props.availableStates[this.state.booking.state].label;
  }

  get icon() {
    return this.props.availableStates[this.state.booking.state].icon;
  }

  handleSelect = state => {
    var data = {
      state: state,
      nextState: this.props.availableStates[state].next,
    };

    Axios.put(`/api/admin/v1/bookings/${this.props.id}`, data)
      .then(() => {
        this.setState(prevState => ({
          booking: { ...prevState.booking, ...data },
        }));
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          window.alert(error.response.data.message);
        }
      });
  };

  capitalizedLabel = label => {
    return label
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  stateLabels = () => {
    return Object.keys(this.props.availableStates).map(state => {
      return this.DropdownItem(state);
    });
  };

  DropdownItem = state => {
    if (this.state.booking.state == state) {
      return (
        <div className="dropdown-item" key={state}>
          <i className="fa fa-check fa-fw" />
          <span className={`badge booking-state--bold booking-state-${state}`}>
            {this.capitalizedLabel(this.props.availableStates[state].label)}
          </span>
        </div>
      );
    } else {
      return (
        <a
          className="dropdown-item"
          href="#"
          key={state}
          onClick={e => {
            e.preventDefault();
            this.handleSelect(state);
          }}
        >
          <i className="fa fa-fw"></i>
          <span className={`badge booking-state--bold booking-state-${state}`}>
            {this.capitalizedLabel(this.props.availableStates[state].label)}
          </span>
        </a>
      );
    }
  };

  statusChanger = () => {
    if (this.state.booking.nextState) {
      var buttonText = '';
      if (this.state.booking.nextState == 'dispatch') {
        buttonText = `Ready for ${this.state.booking.nextState}`;
      } else {
        buttonText = `Mark as ${this.state.booking.nextState}`;
      }
      return (
        <button
          className="btn btn-sm btn-outline-secondary state-marker"
          onClick={e => {
            e.preventDefault();
            this.handleSelect(this.state.booking.nextState);
          }}
        >
          {buttonText}
          <i className={`fal ${this.icon}`} />
        </button>
      );
    }
  };

  render() {
    var buttonClassNames = [
      'btn',
      'btn-primary',
      'btn-sm',
      `booking-state-${this.state.booking.state}`,
      'dropdown-toggle',
    ];
    if (this.props.size == 'xs') buttonClassNames.push('btn-xs');
    if (this.props.block) buttonClassNames.push('btn-block');

    return (
      <div>
        <div className="dropdown booking-state--bold booking-state-dropdown">
          <button
            aria-expanded="false"
            aria-haspopup="true"
            className={buttonClassNames.join(' ')}
            data-toggle="dropdown"
            data-boundary="viewport"
            type="button"
            data-display="static"
          >
            {this.capitalizedLabel(this.label)}
          </button>
          <div
            aria-labelledby="dropdownMenuButton"
            className="dropdown-menu booking-dropdown"
          >
            {this.stateLabels()}
          </div>
        </div>
        {this.statusChanger()}
      </div>
    );
  }
}

StateDropdown.propTypes = {
  id: PropTypes.number,
  state: PropTypes.string,
  size: PropTypes.string,
  block: PropTypes.string,
  availableStates: PropTypes.object,
};
