import { Controller } from 'stimulus';
import Cookie from 'js-cookie';

export default class extends Controller {
  static targets = ['selectedPeriod', 'selectedProgram'];

  initialize() { }

  connect() { }

  update() {
    Cookie.set('selected_period', this.selectedPeriodTarget.value);
    Cookie.set('selected_program', this.selectedProgramTarget.value);
    location.reload();
  }
}
