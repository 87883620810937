import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../Loader';

export default class ColorOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.loaderRef = React.createRef();

  }

  handleChange = (e) => {
    var data = {}
    data[this.props.name] = e.target.value

    this.loaderRef.current.start(this.props.actions.updateUiPreset(data))
  }

  optionsList(props) {
    return this.props.colors.map((color, index) =>
      <div key={index} className="customisation-container__color-pick d-inline-block mr-3">
        <div className="form-check pl-0">
          <div className={`color-pick__sample color-pick__sample--${color} d-inline-block`} style={color=='primary' ? {background: `${this.props.preset.primaryColor}`} : {}}/>
          <br />
          <input
            className="mx-auto d-block form-check-input position-static"
            name={`${this.props.name}-radios`}
            type="radio"
            value={color}
            onChange={this.handleChange}
            checked={this.props.option === `${color}`} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="customisation-container__color-option">
        {this.optionsList(this.props.colors)}
        <Loader ref={this.loaderRef}/>
      </div>
    )
  }
}
