import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["accessLevelSelector", "brandsWrapper"];

  connect() {
    this.update();
  }

  update() {
    switch(this.accessLevelSelectorTarget.value) {
      case 'full':
        this.brandsWrapperTarget.style.display = 'none';
        break;
      case 'brand':
        this.brandsWrapperTarget.style.display = 'block';
        break;
    }
  }
}
