import PropTypes from 'prop-types';
import React from 'react';
import Axios from 'axios';

export default class InlineEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'show',
    };
  }

  edit = () => {
    this.setState((prevState, props) => ({
      mode: 'edit',
    }));
  };

  show = () => {
    this.setState((prevState, props) => ({
      mode: 'show',
    }));
  };

  put = (url, data, callback) => {
    Axios.put(url, data)
      .then(response => {
        callback(response);
        this.show();
      })
      .catch(function (error) {
        console.log(error);
      });

    return false;
  };

  post = (url, data, callback) => {
    Axios.post(url, data)
      .then(response => {
        callback(response);
        this.show();
      })
      .catch(function (error) {
        console.log(error);
      });

    return false;
  };

  render() {
    return this.state.mode == 'edit' ? this.props.editor : this.props.viewer;
  }
}
