import PropTypes from 'prop-types';
import React from 'react';
import InlineEditor from '../InlineEditor';

export default class DeliveryAddressEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: props.id,
        deliveryCountryCode: props.deliveryCountryCode,
        deliveryCounty: props.deliveryCounty,
        deliveryPostcode: props.deliveryPostcode,
        deliveryStreet: props.deliveryStreet,
        deliveryStreet2: props.deliveryStreet2,
        deliveryTown: props.deliveryTown,
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    var data = {
      deliveryCountryCode: this.refs.deliveryCountryCode.value,
      deliveryCounty: this.refs.deliveryCounty.value,
      deliveryPostcode: this.refs.deliveryPostcode.value,
      deliveryStreet: this.refs.deliveryStreet.value,
      deliveryStreet2: this.refs.deliveryStreet2.value,
      deliveryTown: this.refs.deliveryTown.value,
    };

    this.refs.inlineEditor.put(
      `/api/admin/v1/bookings/${this.state.booking.id}`,
      data,
      () => {
        this.setState((prevState, props) => ({
          booking: { ...prevState.booking, ...data },
        }));
      },
    );
  };

  viewer = () => {
    return (
      <span className="editor-wrapper">
        <div>
          <div>{this.state.booking.deliveryStreet}</div>
          <div>{this.state.booking.deliveryStreet2}</div>
          <div>{this.state.booking.deliveryTown}</div>
          <div>{this.state.booking.deliveryPostcode}</div>
          <div>{this.state.booking.deliveryCounty}</div>
          <div>{this.state.booking.deliveryCountryCode}</div>
        </div>
        {['paid', 'pending'].includes(this.props.state) ? (
          <button
            type="button"
            style={{ border: 'none', background: 'none' }}
            onClick={() => {
              this.refs.inlineEditor.edit();
            }}
          >
            <span className="fa fa-pencil" style={{ fontSize: '14px' }}></span>
          </button>
        ) : (
            <div />
          )}
      </span>
    );
  };

  editor = () => {
    return (
      <form className="form-inline" onSubmit={this.handleSubmit}>
        <div className="input-group input-group-sm">
          <input
            type="text"
            placeholder="Delivery Street"
            className="form-control address-input"
            defaultValue={this.state.booking.deliveryStreet}
            ref="deliveryStreet"
          />
          <input
            type="text"
            placeholder="Delivery Street2"
            className="form-control address-input"
            defaultValue={this.state.booking.deliveryStreet2}
            ref="deliveryStreet2"
          />
          <input
            type="text"
            placeholder="Delivery Town"
            className="form-control address-input"
            defaultValue={this.state.booking.deliveryTown}
            ref="deliveryTown"
          />
          <input
            type="text"
            placeholder="Delivery Postcode"
            className="form-control address-input"
            defaultValue={this.state.booking.deliveryPostcode}
            ref="deliveryPostcode"
          />
          <input
            type="text"
            placeholder="Delivery County"
            className="form-control address-input"
            defaultValue={this.state.booking.deliveryCounty}
            ref="deliveryCounty"
          />
          <input
            type="text"
            placeholder="Delivery Country Code"
            className="form-control address-input"
            defaultValue={this.state.booking.deliveryCountryCode}
            ref="deliveryCountryCode"
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary" value="Submit">
              <i className="fa fa-check"></i>
            </button>
            <button
              type="button"
              className="btn btn-link"
              value="Cancel"
              onClick={() => {
                this.refs.inlineEditor.show();
              }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <InlineEditor
        editor={this.editor()}
        viewer={this.viewer()}
        ref="inlineEditor"
      />
    );
  }
}

DeliveryAddressEditor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  state: PropTypes.string,
  deliveryCountryCode: PropTypes.string,
  deliveryCounty: PropTypes.string,
  deliveryPostcode: PropTypes.string,
  deliveryStreet: PropTypes.string,
  deliveryStreet2: PropTypes.string,
  deliveryTown: PropTypes.string,
};
