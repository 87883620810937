import PropTypes from 'prop-types';
import React, { useEffect, createRef, useState } from 'react';

import Loader from './Loader';

const Switch = ({ preset, actions, name, label }) => {
  const [checked, setChecked] = useState(preset[name]);
  const loaderRef = createRef();

  useEffect(() => {
    setChecked(preset[name]);
  }, [preset.id]);

  useEffect(() => {
    var data = {};
    data[name] = checked;
    checked !== preset[name]
      ? loaderRef.current.start(actions.updateUiPreset(data))
      : null;
  }, [checked]);

  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        checked={checked}
        id={`${name}Switch`}
        onChange={e => setChecked(e.target.checked)}
      />
      <label className="custom-control-label" htmlFor={`${name}Switch`}>
        {label}
      </label>
      <Loader ref={loaderRef} />
    </div>
  );
};

Switch.propTypes = {
  preset: PropTypes.object,
  actions: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default Switch;
