import React from 'react';
import PropTypes from 'prop-types';
import MultiDatesPicker from './MultiDatesPicker';
import useSaveToInput from '../../../hooks/useSaveToInput';

const InputLinkedMultiDatesPicker = ({ inputId, ...props }) => {
  const { save } = useSaveToInput(inputId);

  return <MultiDatesPicker onChange={save} {...props} />;
};

InputLinkedMultiDatesPicker.propTypes = {
  inputId: PropTypes.string
};

InputLinkedMultiDatesPicker.defaultProps = {
  inputId: null
};

// eslint-disable-next-line react/display-name
export default props => <InputLinkedMultiDatesPicker {...props} />;
