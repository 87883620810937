import { Controller } from 'stimulus';
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = ['counter'];

  get number() {
    return JSON.parse(this.data.get('number'));
  }
  set number(value) {
    this.data.set('number', JSON.stringify(value));
  }

  initialize() {
    const options = {
      duration: 2,
      decimalPlaces: 0,
      suffix: '%',
    };
    var counter = new CountUp(this.counterTarget, this.number, options);
    counter.start();
  }

  connect() {}
}
