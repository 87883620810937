import React from 'react';
import PropTypes from 'prop-types';

import OpacitySlider from './OpacitySlider';
import ColorOption from './ColorOption';
import Thumbnail from '../Thumbnail';
import TextField from './TextField';
import BetterTextField from './BetterTextField';
import Switch from '../Switch';

export default class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleSuccess(data) {
    this.props.actions.updateUiPreset(data);
  }

  handleDelete(id, e) {
    var result = confirm('Are you sure?');

    var data = {
      id: id,
      name: e.target.value,
    };

    if (result) {
      this.props.actions.purgeAttachment(data);
    } else {
      return;
    }
  }

  render() {
    return (
      <div>
        <h5 className="mt-5">
          <strong>Universal Page Settings</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Call To Action Text</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="cta"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Background Image Overlay</p>
            <p className="small text-secondary mb-4">
              Choose between light, dark or primary colour. This will be applied
              to the second background image as well.
            </p>
          </div>
          <div className="col-sm-3">
            <ColorOption
              preset={this.props.presets.current()}
              option={this.props.presets.current().overlayOption}
              colors={['light', 'dark', 'primary']}
              name="overlayOption"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Overlay Opacity</p>
            <p className="small text-secondary mb-4">
              How much of your background image do you
            </p>
          </div>
          <div className="col-sm-4">
            <OpacitySlider
              preset={this.props.presets.current()}
              opacityValue={this.props.presets.current().overlayOpacity}
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Overlay Text Colour</p>
            <p className="small text-secondary mb-4">
              Light or dark text to go on top of your overlay.
            </p>
          </div>
          <div className="col-sm-3">
            <ColorOption
              preset={this.props.presets.current()}
              option={this.props.presets.current().overlayTextOption}
              colors={['light', 'dark']}
              name="overlayTextOption"
              actions={this.props.actions}
            />
          </div>
        </div>

        <h5 className="mt-5">
          <strong>Section 1 - Landing</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Background Image #1</p>
            <p className="small text-secondary mb-4">
              This is the first image the user will see. Keep the focus of the
              image on right-hand side. 2732 x 1536 pixels (recommended).
            </p>
          </div>
          <div className="col-sm-4">
            <Thumbnail
              preset={this.props.presets.current()}
              name="backgroundImage1"
              className="bg"
              actions={this.props.actions}
              saveAction={this.props.actions.updateUiPreset}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Background Video #1</p>
            <p className="small text-secondary mb-4">
              If you chose to do so upload a video instead! High definition at
              1920 x 1080 pixels or above is recommended.
            </p>
          </div>
          <div className="col-sm-4">
            <Thumbnail
              preset={this.props.presets.current()}
              name="backgroundVideo"
              className="video"
              actions={this.props.actions}
              saveAction={this.props.actions.updateUiPreset}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Header Logo (Optional)</p>
            <p className="small text-secondary mb-4">
              Upload a greyscale image with transparent background at 400 x 140
              pixels (recommended)
            </p>
          </div>
          <div className="col-sm-4">
            <Thumbnail
              preset={this.props.presets.current()}
              name="headerLogo"
              className="logo"
              actions={this.props.actions}
              saveAction={this.props.actions.updateUiPreset}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Headline Logo URL</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="headerUrl"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Headline Text</p>
            <p className="small text-secondary mb-4">
              Customise the welcome headline your customers see.
            </p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="headline"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Lead Text</p>
            <p className="small text-secondary mb-4">
              {`If you customise this text, we recommend you include and keep up to date the delivery fee and ${this.props.mode} duration.`}
            </p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="lead"
              rows="3"
              actions={this.props.actions}
            />
          </div>
        </div>

        <h5 className="mt-5">
          <strong>Section 2 - How it works</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Icon Colour</p>
            <p className="small text-secondary mb-4">
              Choose between dark and your primary colour.
            </p>
          </div>
          <div className="col-sm-2 section__icon-color-col">
            <ColorOption
              preset={this.props.presets.current()}
              option={this.props.presets.current().iconOption}
              colors={['dark', 'primary']}
              name="iconOption"
              actions={this.props.actions}
            />
          </div>
          <div className="col-sm-3 section__icon-preview">
            <div className="customisation-container__thumbnail customisation-container__thumbnail--icon d-inline-block">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
              >
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: `.cls-1{fill:
                        ${
                        this.props.presets.current().iconOption == 'primary'
                          ? this.props.presets.current().primaryColor
                          : 'black'
                        };}
                        `,
                    }}
                  ></style>
                </defs>
                <path
                  className="cls-1"
                  d="M98,60.49a1.72,1.72,0,0,0-1.72,1.72V95.09a1.72,1.72,0,0,1-1.72,1.72H60.66V83.55a1.72,1.72,0,0,0-1.72-1.72H40.7A1.72,1.72,0,0,0,39,83.55V96.81H5.76A1.72,1.72,0,0,1,4,95.09V51a1.72,1.72,0,0,1,.33-1,.27.27,0,0,0,.08-.07l.22-.24a1.71,1.71,0,0,1,1.09-.4H88.55a1.72,1.72,0,0,0,0-3.44H52.06V32.61H78.39a4.6,4.6,0,0,1,3.51,1.62l7.74,9.13a1.72,1.72,0,0,0,2.63-2.23L84.52,32a8,8,0,0,0-6.13-2.83H22.16a8,8,0,0,0-6,2.64l-14,15.44A5.17,5.17,0,0,0,.59,51V95.09a5.17,5.17,0,0,0,5.17,5.16H94.57a5.17,5.17,0,0,0,5.16-5.16V62.21A1.72,1.72,0,0,0,98,60.49ZM42.42,85.28h14.8V96.64H42.42ZM18.75,34.12a4.6,4.6,0,0,1,3.41-1.51H48.61V45.86H8.14Z"
                />
                <path
                  className="cls-1"
                  d="M46.84,11.08l9.68,7.57a1.76,1.76,0,0,0,1.06.36,1.72,1.72,0,0,0,1.06-3.08l-6-4.67H67.55a28.92,28.92,0,0,1,0,57.84,1.72,1.72,0,1,0,0,3.44,32.36,32.36,0,0,0,0-64.72H53.13l5.74-4.49A1.72,1.72,0,1,0,56.75.62L47.26,8a1.6,1.6,0,0,0-.46.36,1.72,1.72,0,0,0,0,2.68Z"
                />
              </svg>
            </div>
            <p className="small text-secondary">Preview</p>
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Step One Title</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="step1Title"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Step One Text</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="step1"
              rows="3"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Step Two Title</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="step2Title"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Step Two Text</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="step2"
              rows="3"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Step Three Title</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="step3Title"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Step Three Text</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="step3"
              rows="3"
              actions={this.props.actions}
            />
          </div>
        </div>

        <h5 className="mt-5">
          <strong>{`Section 3 - Reason to ${this.props.mode}`}</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Background Image #2</p>
            <p className="small text-secondary mb-4">
              A bit further down the page. Keep the focus of the image on
              left-hand side. 2732 x 1536 pixels (recommended).
            </p>
          </div>
          <div className="col-sm-4">
            <Thumbnail
              preset={this.props.presets.current()}
              name="backgroundImage2"
              className="bg"
              actions={this.props.actions}
              saveAction={this.props.actions.updateUiPreset}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Background Video #2</p>
            <p className="small text-secondary mb-4">
              If you chose to do so upload a video instead! High definition at
              1920 x 1080 pixels or above is recommended.
            </p>
          </div>
          <div className="col-sm-4">
            <Thumbnail
              preset={this.props.presets.current()}
              name="backgroundVideo2"
              className="video"
              actions={this.props.actions}
              saveAction={this.props.actions.updateUiPreset}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Section 3 Headline</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="section3Headline"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Section 3 Subtitle</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="section3Subtitle"
              rows="3"
              actions={this.props.actions}
            />
          </div>
        </div>

        <h5 className="mt-5">
          <strong>{`Section 4 - ${this.props.mode.charAt(0).toUpperCase() +
            this.props.mode.slice(1)} Summary`}</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Section 4 Headline</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="section4Headline"
              rows="1"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Section 4 Subtitle</p>
          </div>
          <div className="col-sm-4">
            <TextField
              preset={this.props.presets.current()}
              name="section4Subtitle"
              rows="3"
              actions={this.props.actions}
            />
          </div>
        </div>
        <div>
          <div className="row customisation-container__section">
            <div className="col-sm-3">
              <p className="section__subtitle">Product Image(s)</p>
              <p className="small text-secondary mb-4">
                Show off your product! Choose up to 3 images. 720 x 720 pixels
                or more and a transparent background is recommended.
              </p>
            </div>
            <div className="col-sm-3 section__program-image-col">
              <Thumbnail
                preset={this.props.presets.current()}
                name="productImage1"
                className="product"
                actions={this.props.actions}
                saveAction={this.props.actions.updateUiPreset}
              />
            </div>
            <div className="col-sm-3 section__program-image-col">
              <Thumbnail
                preset={this.props.presets.current()}
                name="productImage2"
                className="product"
                actions={this.props.actions}
                saveAction={this.props.actions.updateUiPreset}
              />
            </div>
            <div className="col-sm-3 section__program-image-col">
              <Thumbnail
                preset={this.props.presets.current()}
                name="productImage3"
                className="product"
                actions={this.props.actions}
                saveAction={this.props.actions.updateUiPreset}
              />
            </div>
          </div>
        </div>
        <h5 className="mt-5">
          <strong>Section 5 - Footer Banner (Optional)</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Display Footer Banner</p>
          </div>
          <div className="col-sm-4">
            <Switch
              preset={this.props.presets.current()}
              actions={this.props.actions}
              name="displayFooter"
            />
          </div>
        </div>
        <div className="row customisation-container__section mt-0">
          <div className="col-sm-3">
            <p className="section__subtitle">Footer Banner</p>
          </div>
          <div className="col-sm-4">
            <BetterTextField
              preset={this.props.presets.current()}
              actions={this.props.actions}
              headline="footerHeadline"
              lead="footerLead"
              headlinePlaceholder="Banner header"
              leadPlaceholder="Banner text"
            />
          </div>
        </div>
        <h5 className="mt-5">
          <strong>Section 6 - Footer Text (Optional)</strong>
        </h5>
        <div className="col-sm-7 p-0">
          <hr align="left" />
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Display Footer Text</p>
          </div>
          <div className="col-sm-4">
            <Switch
              preset={this.props.presets.current()}
              actions={this.props.actions}
              name="displayFootnote"
            />
          </div>
        </div>
        <div className="row customisation-container__section mt-0">
          <div className="col-sm-3">
            <p className="section__subtitle">Footer Text</p>
          </div>
          <div className="col-sm-4">
            <BetterTextField
              preset={this.props.presets.current()}
              actions={this.props.actions}
              headline="footnoteHeadline"
              lead="footnoteLead"
              headlinePlaceholder="Footer header"
              leadPlaceholder="Footer text"
            />
          </div>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  mode: PropTypes.string,
  currentPresetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  presetsList: PropTypes.array,
  lastAction: PropTypes.object,
  presets: PropTypes.object,
  actions: PropTypes.object,
};
