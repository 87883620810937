import { connect } from 'react-redux';
import CustomisationWizard from '../components/AdminPanel/Customisations/CustomisationWizard';
import * as actions from '../actions/admin/program/uiPresetActionCreators';

export const decoratePresets = (state) => {
  var newState = {...state, presets: {}};

  newState.presets.byID = (id) => {
    return state.presetsList.filter(p => (p.id == id))[0];
  };
  newState.presets.current = () => {
    return newState.presets.byID(state.currentPresetId);
  };

  return newState;
}

// Which part of the Redux global state does our component want to receive as props?
export const mapStateToProps = (state) => {
  return decoratePresets({...state.uiPreset});
};

const mapDispatchToProps = (dispatch) => {
  var props = {actions: {}};

  props.actions.publishUiPreset = (payload) => {
    return dispatch(actions.publishUiPreset(payload));
  }

  props.actions.switchUiPreset = (payload) => {
    return dispatch(actions.switchUiPreset(payload));
  }

  props.actions.updateUiPreset = (payload) => {
    return dispatch(actions.updateUiPreset(payload));
  }

  props.actions.cloneUiPreset = (payload) => {
    return dispatch(actions.cloneUiPreset(payload));
  }

  props.actions.newUiPreset = (payload) => {
    return dispatch(actions.newUiPreset(payload));
  }

  props.actions.deleteUiPreset = (payload) => {
    return dispatch(actions.deleteUiPreset(payload));
  }

  props.actions.purgeAttachment = (payload) => {
    return dispatch(actions.purgeAttachment(payload));
  }

  return props;
};

// Don't forget to actually use connect!
// Note that we don't export Envio, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(CustomisationWizard);
