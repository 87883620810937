import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../Loader';

export default class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedDefault: (!this.getCustom()),
      checkedCustom: (this.getCustom()),
      inputText: this.props.preset.texts[this.props.name],
      typing: false,
      typingTimeout: 0
    };

    this.loaderRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.preset.texts[this.props.name] != this.props.preset.texts[this.props.name]) {
      let custom = this.getCustom()
      if (custom) {
        this.setState({ inputText: this.props.preset.texts[this.props.name], checkedDefault: false, checkedCustom: true })
        this.inputRef.current.style.display = "block"
      } else {
        this.setState({ inputText: "", checkedDefault: true, checkedCustom: false })
        this.inputRef.current.style.display = "none"
      }
    }
  }

  componentDidMount() {
    this.state.inputText ? this.inputRef.current.style.display = "block" : this.inputRef.current.style.display = "none"
  }

  getCustom() {
    return (this.props.preset.texts[this.props.name] ? true : false)
  }

  handleRadio = (e) => {
    var data = {}

    if (e.target.value === "default") {
      data[this.props.name + "Text"] = null
      this.loaderRef.current.start(this.props.actions.updateUiPreset(data))
      this.setState({ inputText: "", checkedDefault: true, checkedCustom: false })
      this.inputRef.current.style.display = "none"
    } else {
      this.setState({ inputText: "", checkedDefault: false, checkedCustom: true })
      this.inputRef.current.style.display = "block"
    }
  }

  handleInput = (e) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      inputText: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        var data = {}
        data[this.props.name + "Text"] = this.state.inputText.trim()
        this.loaderRef.current.start(this.props.actions.updateUiPreset(data))
      }.bind(this), 1000)
    })
  }

  input = (props) => {
    return (
      <div className="form-group mt-4" ref={this.inputRef}>
        <textarea
          className="form-control"
          rows={this.props.rows}
          value={this.state.inputText}
          onChange={this.handleInput}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="customisation-container__text-field-container">
        <div className="d-inline-block">
          <div className="custom-control custom-radio">
            <input
              className="custom-control-input"
              type="radio"
              name={`${this.props.name}TextRadios`}
              id={`${this.props.name}TextRadios1`}
              value="default"
              checked={this.state.checkedDefault}
              onChange={this.handleRadio}
            />
            <label className="custom-control-label" htmlFor={`${this.props.name}TextRadios1`}>Envio's default</label>
          </div>
          <p className="form-group mt-4 mb-4 text-secondary" dangerouslySetInnerHTML={{ __html: this.props.preset.defaultTexts[this.props.name] }}></p>
          <div className="custom-control custom-radio">
            <input
              className="custom-control-input"
              type="radio"
              name={`${this.props.name}TextRadios`}
              id={`${this.props.name}TextRadios2`}
              value="custom"
              checked={this.state.checkedCustom}
              onChange={this.handleRadio}
            />
            <label className="custom-control-label" htmlFor={`${this.props.name}TextRadios2`}>Custom</label>
          </div>
        </div>
        <div className="d-inline-block">
          <Loader ref={this.loaderRef} />
        </div>
        {this.input()}
      </div>
    )
  }
}
