import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import Cookie from 'js-cookie';

import Loader from '../Loader';

export default class StickyMenu extends React.Component {
  constructor(props) {
    super(props);

    this.stickyMenuRef = React.createRef();
    this.loaderRef = React.createRef();

    this.state = {
      w: null,
    }

  }

  componentDidMount() {
    this.getLastOpenedPreset()
    window.addEventListener('scroll', this.handleScroll)
  }

  getLastOpenedPreset() {
    var cookie = Cookie.get('last_opened_ui_preset_id')

    if (cookie) {
      this.props.actions.switchUiPreset(cookie)
    }
  }

  handleScroll = () => {
    const node = this.stickyMenuRef.current
    window.pageYOffset >= 200 ? node.className = "customisation-container sticky-menu sticky-menu--sticky" : node.className = "customisation-container sticky-menu"
  }

  handleSwitch = (e) => {
    this.props.actions.switchUiPreset(e.target.value)
  }

  handlePublish = () => {
    this.loaderRef.current.start(this.props.actions.publishUiPreset(this.props.presets.current().id))
  }

  handleNew = (e) => {
    this.loaderRef.current.start(this.props.actions.newUiPreset())
  }

  handleClone = (e) => {
    this.loaderRef.current.start(this.props.actions.cloneUiPreset())
  }

  handleDelete = (e) => {
    var result = confirm("Are you sure?")

    if (result) {
      var data = {
        id: e.target.id
      }

      this.props.actions.deleteUiPreset(data)

    } else {
      return;
    }
  }

  handleMobile = (e) => {
    this.stickyMenuRef.current.style.display ? this.stickyMenuRef.current.style.display = '' : this.stickyMenuRef.current.style.display = 'block'
  }

  handlePreview = () => {
    var url = this.props.presets.current().previewUrl;

    if (!this.state.w || this.state.w.closed ) {
      this.state.w = window.open(url, '_blank')
    } else {
      this.state.w.postMessage(url, '*')
      this.state.w.focus();
    }
  }

  presetsList = () => {
    return this.props.presetsList.map((preset) =>
    <li key={preset.id}>
      {preset.published && <span className="badge badge-primary">Published</span> }
      <button
        className={classnames('btn btn-link text-secondary sticky-menu__link mt-3 d-inline-block' , {'text-dark font-weight-bold': (this.props.presets.current().id == preset.id)}, {'sticky-menu__link--published': (preset.published)})}
        value={preset.id}
        onClick={this.handleSwitch}>

        <div className='sticky-menu__sample d-inline-block' style={{background: preset.primaryColor}}></div>
        {moment(preset.updatedAt).format('llll')}
      </button>

      {(!preset.published && this.props.presetsList.length !== 1) && <i className="fa fa-times ml-3 d-inline-block" onClick={this.handleDelete} id={preset.id}></i>}
    </li>
    )
  }

  render() {
    return (
      <div>
        <div className="sticky-menu__mobile" onClick={this.handleMobile}>
          <div className="mobile__chevron mx-auto my-auto">
            <i className="fa fa-chevron-up" aria-hidden="true"></i>
          </div>
        </div>
        <div className="customisation-container sticky-menu" ref={this.stickyMenuRef}>
          <div className="row">
            <div className="col">
              <Loader ref={this.loaderRef}/>
              <button className={classnames('btn btn-primary float-right mt-0', {'disabled': (this.props.presets.current().published)})} onClick={this.handlePublish} disabled={this.props.presets.current().published}>Publish</button>
            </div>
            <div className="col">
              <a className="btn btn-secondary float-right" onClick={this.handlePreview}>Preview</a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="text-secondary small font-italic float-right mt-3 mb-0">Last updated {moment(this.props.presets.current().updatedAt).fromNow()}</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ul>
                {this.presetsList()}
              </ul>
            </div>
          </div>
          <div className="sticky-menu__buttons">
            <div className="row">
              <div className="col">
                <div className="btn btn-link p-0 mt-0 d-inline float-right" onClick={this.handleNew}>
                  <i className="fa fa-plus mr-2"></i>
                  <span className="font-weight-bold">New</span>
                </div>
              </div>
              <div className="col">
                <div className="btn btn-link p-0 mt-0 d-inline float-left" onClick={this.handleClone}>
                  <i className="fa fa-clone mr-2"></i>
                  <span className="font-weight-bold">Clone</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
