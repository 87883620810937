import { hot } from 'react-hot-loader'
import React from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';

import ProgramPurchasingContainer from '../containers/ProgramPurchasingContainer';

const ProgramPurchasingApp = (props, _railsContext) => (
  <Provider store={ReactOnRails.getStore('AdminStore')}>
    <ProgramPurchasingContainer />
  </Provider>
);

export default hot(module)(ProgramPurchasingApp);
