import PropTypes from 'prop-types';
import React from 'react';
import Axios from 'axios';

export default class StateButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: props.id,
        state: this.props.state,
        nextState: this.props.availableStates[this.props.state].next,
      },
    };
  }

  get nextState() {
    return this.props.availableStates[this.state.booking.state].next;
  }

  get color() {
    return this.props.availableStates[this.state.booking.state].color;
  }

  get label() {
    return this.props.availableStates[this.state.booking.state].label;
  }

  get icon() {
    return this.props.availableStates[this.state.booking.state].icon;
  }

  capitalizedLabel = () => {
    return this.label
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  pushStatus = () => {
    var nextState = this.props.availableStates[this.state.booking.nextState];

    Axios.put(`/api/admin/v1/bookings/${this.props.id}`, {
      state: this.state.booking.nextState,
    })
      .then(response => {
        this.setState({
          booking: {
            state: this.state.booking.nextState,
            nextState: nextState.next,
          },
        });
      })
      .catch(function (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          window.alert(error.response.data.message);
        }
      });
  };

  statusChanger = () => {
    if (this.state.booking.nextState) {
      var buttonText = '';
      if (this.state.booking.nextState == 'dispatch') {
        buttonText = `Ready for ${this.state.booking.nextState}`;
      } else {
        buttonText = `Mark as ${this.state.booking.nextState}`;
      }
      return (
        <button
          className="btn btn-xs btn-outline-secondary state-marker"
          onClick={e => {
            e.preventDefault();
            this.pushStatus();
          }}
        >
          {buttonText}
          <i className={`fal ${this.icon}`} />
        </button>
      );
    }
  };

  render() {
    return (
      <div className="booking-state">
        <div className="d-inline">
          <div
            className={`badge badge-primary booking-state--bold booking-state-${this.state.booking.state}`}
          >
            {this.capitalizedLabel()}
          </div>
        </div>
        {this.statusChanger()}
      </div>
    );
  }
}

StateButton.propTypes = {
  id: PropTypes.number,
  state: PropTypes.string,
  availableStates: PropTypes.object,
};
