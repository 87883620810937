import PropTypes from 'prop-types';
import React from 'react';
import InlineEditor from '../InlineEditor';

export default class BookingTrackingEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: props.bookingDefaults.id,
        shipmentTrackingCode: (this.props.way === "onward" ? props.bookingDefaults.shipmentTrackingCode : props.bookingDefaults.shipmentReturnTrackingCode),
        shipmentTrackingUrl: (this.props.way === "onward" ? props.bookingDefaults.shipmentTrackingUrl : props.bookingDefaults.shipmentReturnTrackingUrl)
      }
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...(this.props.way === "onward") && { shipmentTrackingCode: this.refs.shipmentTrackingCode.value },
      ...(this.props.way === "return") && { shipmentReturnTrackingCode: this.refs.shipmentTrackingCode.value }
    }

    this.refs.inlineEditor.put(
      `/api/admin/v1/bookings/${this.state.booking.id}`,
      data,
      ((response) => {
        this.setState((prevState) => ({
          booking: {
            ...prevState.booking,
            ...{
              ...(this.props.way === "onward") && {
                shipmentTrackingCode: response.data.shipmentTrackingCode,
                shipmentTrackingUrl: response.data.shipmentTrackingUrl,
              },
              ...(this.props.way === "return") && {
                shipmentTrackingCode: response.data.shipmentReturnTrackingCode,
                shipmentTrackingUrl: response.data.shipmentReturnTrackingUrl
              }
            }
          }
        }));
      })
    );
  }

  trackingElement = () => {
    if (this.state.booking.shipmentTrackingUrl) {
      return (
        <a href={this.state.booking.shipmentTrackingUrl} target='_blank'>
          {this.state.booking.shipmentTrackingCode}
        </a>
      )
    } else {
      return (
        this.state.booking.shipmentTrackingCode
      )
    }
  }

  emptyViewer = () => {
    return (
      <span>
        {this.trackingElement()}
        <button className="tracking-editor__btn--empty btn btn-link" type="button" onClick={() => {this.refs.inlineEditor.edit()}}>
          <i className="fa fa-plus" />
          <strong>Add tracking number</strong>
        </button>
      </span>
    )
  }

  filledViewer = () => {
    return (
      <span>
        {this.trackingElement()}
        <button className="tracking-editor__btn" type="button" onClick={() => {this.refs.inlineEditor.edit()}}>
          <span className="fa fa-pencil"></span>
        </button>
      </span>
    )
  }

  viewer = () => {
    if(this.state.booking.shipmentTrackingCode) {
      return this.filledViewer()
    } else {
      return this.emptyViewer()
    }
  }

  editor = () => {
    return (
      <form className="form-inline" onSubmit={this.handleSubmit}>
        <div className="input-group input-group-sm">
          <input type="text" placeholder="Tracking code" className="form-control" defaultValue={this.state.booking.shipmentTrackingCode} ref="shipmentTrackingCode" />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary" value="Submit">
              <i className="fa fa-check"></i>
            </button>
            <button type="button" className="btn btn-link" value="Cancel" onClick={() => {this.refs.inlineEditor.show()}} >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <InlineEditor
        editor={this.editor()}
        viewer={this.viewer()}
        ref="inlineEditor"
      />
    );
  }
}
