import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Uploader from './Uploader';
import Loader from './Loader';

export default class Thumbnail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.getFileName = this.getFileName.bind(this);
    this.dispatchSave = this.dispatchSave.bind(this);
    this.loaderRef = React.createRef();
  }

  getFileName() {
    if (this.props.preset) {
      return this.props.preset[this.props.name]
    } else {
      return this.props.name
    }
  }

  handleDelete(id, e) {
    var result = confirm("Are you sure?")

    var data = {
      id: id,
      name: e.target.value
    }

    if (result) {
      this.loaderRef.current.start(this.props.actions.purgeAttachment(data))
    } else {
      return;
    }
  }

  handleRestrictions = (name) => {
    switch (name) {
      case "backgroundImage1":
        return { maxFileSize: 5000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "backgroundImage2":
        return { maxFileSize: 5000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "backgroundVideo":
        return { maxFileSize: 10000000, allowedFileTypes: ['.ogg', '.mp4', '.webm'] }
      case "backgroundVideo2":
        return { maxFileSize: 10000000, allowedFileTypes: ['.ogg', '.mp4', '.webm'] }
      case "headerLogo":
        return { maxFileSize: 1000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "productImage1":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "productImage2":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "productImage3":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "bannerImage1":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "bannerImage2":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "bannerImage3":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "bannerImage4":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "bannerImage5":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      case "bannerImage6":
        return { maxFileSize: 2000000, allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'] }
      default:
        return {};
    }
  }

  dispatchSave = (file, resp) => {
    var data = {}
    data[this.getFileName().uploader.attributeName] = resp.signed_id

    return this.props.saveAction(data)
  }

  dispatchError = (resp) => {
    this.setState({ error: resp })
  }

  image = () => {
    return (
      <div className={`customisation-container__thumbnail customisation-container__thumbnail--${this.props.className}-container mt-4 d-inline-block`}>
        <div className={`customisation-container__thumbnail--${this.props.className}-container__image`} style={{ backgroundImage: `url(${this.getFileName().previewUrl})` }}>
          {this.props.preset[this.props.name].previewUrl != "" &&
            <button className="btn btn-secondary btn-delete-bg" value={this.props.name} onClick={(e) => this.handleDelete(this.getFileName().attachmentId, e)}>✕</button>}
        </div>
        <p className="small text-secondary">{this.props.preset[this.props.name].filename}</p>
      </div>
    )
  }

  video = () => {
    return (
      <div className={`customisation-container__thumbnail customisation-container__thumbnail--${this.props.className}-container mt-4 d-inline-block`}>
        <div className={`customisation-container__thumbnail--${this.props.className}-container__video`}>
          {this.props.preset[this.props.name].previewUrl != "" &&
            <button className="btn btn-secondary btn-delete-bg" value={this.props.name} onClick={(e) => this.handleDelete(this.getFileName().attachmentId, e)}>✕</button>}
          <video autoPlay loop muted src={this.getFileName().previewUrl} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="customisation-container__thumbnail-container">
        <Uploader
          objectName={this.getFileName().uploader.objectName}
          attributeName={this.getFileName().uploader.attributeName}
          inputId={this.getFileName().uploader.inputId}
          directUploadPath={this.getFileName().uploader.directUploadPath}
          restrictions={this.handleRestrictions(this.props.name)}

          published={this.getFileName().published}
          dispatchSave={this.dispatchSave}
          dispatchError={this.dispatchError}
        />
        <div className={classnames("alert alert-danger small d-none", { 'd-block': (this.state.error != false) })}>{this.state.error}</div>
        {this.props.name.includes('Video') ? this.video() : this.image()}
        <Loader ref={this.loaderRef} />
      </div>
    )
  }

}
