import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['conversionChart'];

  initialize() {
    this.drawChart();
  }

  get bookings() {
    return JSON.parse(this.data.get('bookings'));
  }

  set bookings(value) {
    this.data.set('bookings', JSON.stringify(value));
    this.drawChart();
  }

  set programs(value) {
    this.data.set('programs', JSON.stringify(value));
    this.drawChart();
  }

  get programs() {
    return JSON.parse(this.data.get('programs'));
  }

  datasets = () => {
    const dataset = [
      {
        label: 'Purchase Conversion',
        backgroundColor: 'rgb(63, 191, 191)',
        borderColor: 'rgb(63, 191, 191)',
        data: this.bookings.converted,
      },
      {
        label: 'Bookings',
        backgroundColor: 'rgb(2,117,216)',
        borderColor: 'rgb(2,117,216)',
        data: this.bookings.all,
      },
    ];

    if (this.programs.availability) {
      dataset.unshift({
        type: 'line',
        label: 'Wait days',
        fill: false,
        backgroundColor: 'rgba(221, 120, 120, 0.7)',
        borderColor: 'rgba(221, 120, 120, 1)',
        pointBorderColor: 'rgba(221, 120, 120, 1)',
        data: this.programs.availability,
        steppedLine: true,
      });
    }

    return dataset;
  };

  drawChart() {
    this.chart = new Chart(this.conversionChartTarget, {
      type: 'bar',
      data: {
        labels: this.bookings.labels,
        datasets: this.datasets(),
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Bookings',
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
              },
            },
          ],
        },
      },
    });
  }
}
