import React from 'react';
import InventoryStockLevelsTable from './InventoryStockLevelsTable';
import useViewStockLevelsData from './useViewStockLevelsData';

// eslint-disable-next-line react/prop-types
const ViewDataStockLevelsTable = ({ data }) => (
  <InventoryStockLevelsTable {...useViewStockLevelsData(data)} />
);

// eslint-disable-next-line react/display-name
export default props => <ViewDataStockLevelsTable {...props} />;
