import React from 'react';
import moment from 'moment';

import { LOW_VALUE } from './InventoryStockLevelsTable';

export function generateDatesArray(startDate, todayDate) {
  const daysInMonthArray = [];
  const daysInMonthHeaders = [];

  for (let d = 0; d < moment(startDate).daysInMonth(); d++) {
    const day = moment(startDate).add(d, 'days');
    const formattedDay = moment(day).format('YYYY-MM-DD');
    daysInMonthArray.push(formattedDay);
    const isToday = formattedDay === todayDate;
    daysInMonthHeaders.push(
      <th key={day} className={isToday ? 'today' : ''}>
        <span>{moment(day).format('dd')}</span>
        <br />
        <span>{moment(day).format('DD')}</span>
      </th>,
    );
  }

  return { daysInMonthArray, daysInMonthHeaders };
}

export function setCellClassName(isData, dataValue, day, todayDate) {
  let classNameText = 'normal-value';

  if (!isData) {
    classNameText = 'no-data';
  } else if (dataValue <= 0) {
    classNameText = 'empty';
  } else if (dataValue <= LOW_VALUE) {
    classNameText = 'low-value';
  }
  if (day === todayDate) classNameText += ' today-cell';
  if (Date.parse(day) < Date.parse(todayDate)) {
    classNameText += ' past-day-cell';
  }

  return classNameText;
}
