import { useState, useMemo, useCallback, useEffect } from 'react';
import moment from 'moment';

export default data => {
  const todayDate = moment().startOf('month');
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(false);

  const moveDateBy = (value, unit = 'month') => {
    const newDate = moment(selectedDate.add(value, unit).startOf('month'));
    const dateDiffMoreThanOneMonth = todayDate.diff(newDate, 'months') > 0;
    setPreviousButtonDisabled(dateDiffMoreThanOneMonth);
    setSelectedDate(newDate);
  };

  useEffect(() => {
    moveDateBy(0);
  }, []);

  return {
    previousMonth: useCallback(() => {
      moveDateBy(-1);
    }),
    nextMonth: useCallback(() => {
      moveDateBy(1);
    }),
    startDate: selectedDate,
    data,
    previousButtonDisabled,
  };
};
