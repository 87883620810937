import Axios from 'axios';
import humps from 'humps';

import { ADMIN_PROGRAM_PURCHASE_UPDATE } from '../../../constants/admin/program/purchaseConstants';
import { ADMIN_PROGRAM_PURCHASE_UI_PRESET_UPDATE } from '../../../constants/admin/program/purchaseConstants';
import { ADMIN_ACTIVE_STORAGE_PURCHASE_ATTACHMENT_DELETE } from '../../../constants/admin/program/purchaseConstants';

import { mapStateToProps as mapProgramStateToProps }  from '../../../containers/ProgramPurchasingContainer';

export const updatePurchaseUiPresetAction = (payload) => ({
  type: ADMIN_PROGRAM_PURCHASE_UI_PRESET_UPDATE,
  payload,
});

export const updateProgramPurchaseAction = (payload) => ({
  type: ADMIN_PROGRAM_PURCHASE_UPDATE,
  payload,
});

export const purgeAttachmentAction = (payload) => ({
  type: ADMIN_ACTIVE_STORAGE_PURCHASE_ATTACHMENT_DELETE,
  payload,
});

export const updatePurchaseUiPreset = (data) => {
  return function(dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    return Axios({
      method: 'put',
      url: `/api/admin/v1/programs/${state.preset.programId}/purchase_ui_presets/${state.preset.id}`,
      responseType: 'json',
      data: humps.decamelizeKeys(data),
    }).then(response => {
      dispatch(updatePurchaseUiPresetAction(response.data.purchase_ui_preset));
      return response;
    });
  };
}

export const updateProgramPurchase = (data) => {
  return function(dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    return Axios({
      method: 'put',
      url: `/api/admin/v1/programs/${state.preset.programId}`,
      responseType: 'json',
      data: humps.decamelizeKeys(data),
    }).then(response => {
      dispatch(updateProgramPurchaseAction(response));
      return response;
    });
  };
}

export const purgeAttachment = (data) => {
  return function(dispatch, getState) {
    const state = mapProgramStateToProps(getState());

    return Axios({
      method: 'delete',
      url: `/api/admin/v1/active_storage/attachments/${data.id}`,
      responseType: 'json',
      params: data,
    }).then(response => {
      dispatch(purgeAttachmentAction(response));
      return response;
    })
  }
}
