import { hot } from 'react-hot-loader'
import React from 'react';
import { Provider } from 'react-redux';
import ReactOnRails from 'react-on-rails';

import ProgramCustomisationContainer from '../containers/ProgramCustomisationContainer';

const ProgramCustomisationApp = (props, _railsContext) => (
  <Provider store={ReactOnRails.getStore('AdminStore')}>
    <ProgramCustomisationContainer />
  </Provider>
);

export default hot(module)(ProgramCustomisationApp);
