import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['links', 'template', 'addButton'];

  connect() {
    this.wrapperClass = this.data.get('wrapperClass') || 'nested-fields';
    this.validateAddButton();
  }

  get limit() {
    return this.data.get('limit');
  }

  validateAddButton() {
    var fieldCounter = document.querySelectorAll(
      '.nested-fields:not(.marked-for-destruction)',
    ).length;

    if (fieldCounter >= this.limit) {
      this.addButtonTarget.style.pointerEvents = 'none';
      this.addButtonTarget.style.cursor = 'default';
      this.addButtonTarget.classList.add('disabled');
    } else {
      this.addButtonTarget.style.pointerEvents = 'auto';
      this.addButtonTarget.style.cursor = 'pointer';
      this.addButtonTarget.classList.remove('disabled');
    }
  }

  addAssociation(event) {
    event.preventDefault();

    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );
    this.linksTarget.insertAdjacentHTML('beforebegin', content);

    this.validateAddButton();
  }

  removeAssociation(event) {
    event.preventDefault();

    let wrapper = event.target.closest('.' + this.wrapperClass);

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == 'true') {
      wrapper.remove();

      // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      wrapper.style.display = 'none';
      wrapper.classList.add('marked-for-destruction');
    }

    this.validateAddButton();
  }
}
