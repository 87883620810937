import { Controller } from "stimulus"
import print from 'print-js'

export default class extends Controller {
  static targets = ['icon'];

  perform() {
    print({
      printable: this.data.get('path'),
      onLoadingStart: () => { this.iconTarget.className = 'fa fa-spinner fa-spin' },
      onLoadingEnd: () => { this.iconTarget.className = 'fa fa-print' }
    });
  }
}
