import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './InventoryStockLevelsTable.css';
import { generateDatesArray, setCellClassName } from './utils';

export const LOW_VALUE = 3;

const InventoryStockLevelTable = ({
  previousMonth,
  nextMonth,
  startDate,
  data,
  previousButtonDisabled,
}) => {
  const todayDate = moment().format('YYYY-MM-DD');
  const { daysInMonthArray, daysInMonthHeaders } = generateDatesArray(
    startDate,
    todayDate,
  );

  const programRows = data.map(({ name, id, data }) => {
    const programColumns = daysInMonthArray.map((day, index) => {
      const dataValue = data[day]?.amount;
      const isData = !!dataValue || dataValue === 0;

      let classNameText = setCellClassName(isData, dataValue, day, todayDate);

      return (
        <td key={`${name}-${index}`} className={classNameText}>
          {isData && dataValue}
        </td>
      );
    });

    programColumns.unshift(<td key={`${name}-${id}-name`}>{name}</td>);

    return <tr key={`${name}-${id}`}>{programColumns}</tr>;
  });

  return (
    <div className="stock-levels-table">
      <div className="navigation">
        <button
          className="btn btn-primary"
          onClick={previousMonth}
          disabled={previousButtonDisabled}
        >
          <i className="fas fa-caret-left"></i>
        </button>
        <h5>{moment(startDate).format('MMMM YYYY')}</h5>
        <button className="btn btn-primary" onClick={nextMonth}>
          <i className="fas fa-caret-right"></i>
        </button>
      </div>
      <div className="table-wrapper">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="program-column">PROGRAM</th>
              {daysInMonthHeaders}
            </tr>
          </thead>
          <tbody>{programRows}</tbody>
        </table>
      </div>
    </div>
  );
};

InventoryStockLevelTable.propTypes = {
  previousMonth: PropTypes.func.isRequired,
  nextMonth: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      data: PropTypes.objectOf(
        PropTypes.shape({
          date: PropTypes.instanceOf(Date),
          amount: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  previousButtonDisabled: PropTypes.bool,
};

export default InventoryStockLevelTable;
