import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import InlineEditor from '../InlineEditor';

export default class PeriodEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: props.id,
        periodMin: props.periodMin,
        periodMax: props.periodMax,
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    var data = {
      periodMin: this.refs.periodMin.value,
      periodMax: this.refs.periodMax.value,
    };

    this.refs.inlineEditor.put(
      `/api/admin/v1/bookings/${this.state.booking.id}/alter_period`,
      data,
      () => {
        this.setState(prevState => ({
          booking: { ...prevState.booking, ...data },
        }));
      },
    );
  };

  viewer = () => {
    return (
      <span className="editor-wrapper">
        {moment(this.state.booking.periodMin).format('ddd D MMM')}
        <i className="fa fa-long-arrow-right mx-1" />
        {moment(this.state.booking.periodMax).format('ddd D MMM')}
        <button
          type="button"
          style={{ border: 'none', background: 'none' }}
          onClick={() => {
            this.refs.inlineEditor.edit();
          }}
        >
          <span className="fa fa-pencil" style={{ fontSize: '14px' }}></span>
        </button>
      </span>
    );
  };

  editor = () => {
    return (
      <form className="form-inline" onSubmit={this.handleSubmit}>
        <div className="input-group input-group-sm">
          <input
            type="date"
            placeholder="yyyy/mm/dd"
            className="form-control"
            defaultValue={this.state.booking.periodMin}
            ref="periodMin"
          />
          <input
            type="date"
            placeholder="yyyy/mm/dd"
            className="form-control"
            defaultValue={this.state.booking.periodMax}
            ref="periodMax"
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary" value="Submit">
              <i className="fa fa-check"></i>
            </button>
            <button
              type="button"
              className="btn btn-link"
              value="Cancel"
              onClick={() => {
                this.refs.inlineEditor.show();
              }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </form>
    );
  };

  render() {
    return (
      <InlineEditor
        editor={this.editor()}
        viewer={this.viewer()}
        ref="inlineEditor"
      />
    );
  }
}

PeriodEditor.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  periodMin: PropTypes.string,
  periodMax: PropTypes.string,
};
