import PropTypes from 'prop-types';
import React, { useEffect, createRef, useRef, useState } from 'react';

import Loader from '../Loader';

const BetterTextField = ({
  preset,
  actions,
  headline,
  lead,
  headlinePlaceholder,
  leadPlaceholder,
}) => {
  const [headlineValue, setHeadlineValue] = useState(preset.texts[headline]);
  const [leadValue, setLeadValue] = useState(preset.texts[lead]);
  const timeoutRef = useRef(null);
  const loaderRef = createRef();

  useEffect(() => {
    setHeadlineValue(preset.texts[headline]);
    setLeadValue(preset.texts[lead]);
  }, [preset.id]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      headlineValue !== preset.texts[headline]
        ? save(headline, headlineValue)
        : null;
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [headlineValue]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      leadValue !== preset.texts[lead] ? save(lead, leadValue) : null;
    }, 1000);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [leadValue]);

  const save = (name, value) => {
    var data = {};
    data[name + 'Text'] = value.trim();
    loaderRef.current.start(actions.updateUiPreset(data));
  };

  return (
    <div>
      <div className="form-group mt-0">
        <textarea
          className="form-control"
          rows="1"
          value={headlineValue}
          placeholder={headlinePlaceholder}
          onChange={e => setHeadlineValue(e.target.value)}
        />
      </div>
      <div className="form-group mt-4">
        <textarea
          className="form-control"
          rows="3"
          value={leadValue}
          placeholder={leadPlaceholder}
          onChange={e => setLeadValue(e.target.value)}
        />
      </div>
      <Loader ref={loaderRef} />
    </div>
  );
};

BetterTextField.propTypes = {
  preset: PropTypes.object,
  actions: PropTypes.object,
  headline: PropTypes.string,
  lead: PropTypes.string,
  switchLabel: PropTypes.string,
  headlinePlaceholder: PropTypes.string,
  leadPlaceholder: PropTypes.string,
};

export default BetterTextField;
