import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $('#bookings').on('smart_listing:update_list', e => {
      var trElem = $(e.target)
        .find('tbody')
        .find('tr');
      if (trElem.length == 1) {
        $(trElem)
          .find('a.edit[title="Edit"]')
          .trigger('click');
      }
    });
  }
}
