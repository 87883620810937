import PropTypes from 'prop-types';
import React from 'react';
import CurrencyInput from 'react-currency-input';
import Loader from '../Loader';

export default class NewUnitPriceInput extends React.Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = {
      checked: this.props.checked,
      input: this.props.price/100,
      typing: false,
      typingTimeot: 0,
      afterDemo: this.props.afterDemo,
    };

    this.loaderRef = React.createRef();
  }

  formatCurrency(num) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(num)
  }

  handleCheckbox = (e) => {
    if (e.target.checked === false) {
      this.setState({afterDemo: false})
    }
    this.setState({checked: e.target.checked})
    var data = {}
    data[`enable${this.props.name.replace(/./,this.props.name[0].toUpperCase())}`] = e.target.checked
    this.loaderRef.current.start(this.props.actions.updatePurchaseUiPreset(data))
  }

  handleAfterDemoCheckbox = (e) => {
    this.setState({afterDemo: e.target.checked})
    var data = {}
    data['enableAfterDemo'] = e.target.checked
    this.loaderRef.current.start(this.props.actions.updatePurchaseUiPreset(data))
  }

  handleInput = (e, value) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      input: value,
      typing: false,
      typingTimeout: setTimeout(function (){
        var data = {}
        data[this.props.name] = this.state.input.replace(/[^0-9|-]/g, "")
        this.loaderRef.current.start(this.props.actions.updateProgramPurchase(data))
      }.bind(this), 2000)
    })
  }

  render() {
    return(
      <div className="purchasing-container__price-input">
        <div className="w-75 d-inline-block">
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              name={this.props.name}
              id={this.props.id}
              value="default"
              checked={this.state.checked}
              onChange={this.handleCheckbox}
              />
            <label className="custom-control-label small text-secondary" htmlFor={this.props.id}>Click to enable</label>
          </div>
          <p className="small text-secondary">{this.props.text}</p>
          <div className="custom-control custom-checkbox">
            <input
              id="afterDemoCheckbox"
              className="custom-control-input"
              type="checkbox"
              checked={this.state.afterDemo}
              onChange={this.handleAfterDemoCheckbox}
              disabled={!this.state.checked}
              />
            <label className="custom-control-label small text-secondary" htmlFor="afterDemoCheckbox">{`Only display this option after ${this.props.mode} has finished`}</label>
          </div>
        </div>
        <div className="d-inline-block">
          <Loader ref={this.loaderRef}/>
        </div>
        <div className="input-group mt-2">
          <div className="input-group-prepend">
            <div className="input-group-text">{this.props.currency == "gbp" ? '£' : '$'}</div>
          </div>
          <CurrencyInput className="form-control" value={this.state.input} onChangeEvent={this.handleInput} disabled={!this.state.checked}/>
        </div>
      </div>
    )
  }
}
