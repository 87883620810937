import PropTypes from 'prop-types';
import React from 'react';

import Uploader from '../Uploader';
import Thumbnail from '../Thumbnail';

export default class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h5 className="mt-5">
          <strong>Booking Pop-Up</strong>
        </h5>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Banner Image #1</p>
            <p className="small text-secondary mb-4">
              Banner image for the 'Date Selection' window.
              1410 x 366 px (recommended)
            </p>
          </div>
          <div className="col-4">
            <Thumbnail preset={this.props.presets.current()} name="bannerImage1" className="logo" actions={this.props.actions} saveAction={this.props.actions.updateUiPreset}/>
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Banner Image #2</p>
            <p className="small text-secondary mb-4">
              Banner image for the 'Personal Details' window.
              1410 x 366 px (recommended)
            </p>
          </div>
          <div className="col-4">
            <Thumbnail preset={this.props.presets.current()} name="bannerImage2" className="logo" actions={this.props.actions} saveAction={this.props.actions.updateUiPreset}/>
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Banner Image #3</p>
            <p className="small text-secondary mb-4">
              Banner image for the 'Pick-Up Location' window.
              1410 x 366 px (recommended)
            </p>
          </div>
          <div className="col-4">
            <Thumbnail preset={this.props.presets.current()} name="bannerImage3" className="logo" actions={this.props.actions} saveAction={this.props.actions.updateUiPreset}/>
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Banner Image #4</p>
            <p className="small text-secondary mb-4">
              Banner image for the 'Booking Summary' window.
              1410 x 366 px (recommended)
            </p>
          </div>
          <div className="col-4">
            <Thumbnail preset={this.props.presets.current()} name="bannerImage4" className="logo" actions={this.props.actions} saveAction={this.props.actions.updateUiPreset}/>
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Banner Image #5</p>
            <p className="small text-secondary mb-4">
              Banner image for the 'Payment' window.
              1410 x 366 px (recommended)
            </p>
          </div>
          <div className="col-4">
            <Thumbnail preset={this.props.presets.current()} name="bannerImage5" className="logo" actions={this.props.actions} saveAction={this.props.actions.updateUiPreset}/>
          </div>
        </div>
        <div className="row customisation-container__section">
          <div className="col-sm-3">
            <p className="section__subtitle">Banner Image #6</p>
            <p className="small text-secondary mb-4">
              Banner image for the 'Confirmation' window.
              1410 x 366 px (recommended)
            </p>
          </div>
          <div className="col-4">
            <Thumbnail preset={this.props.presets.current()} name="bannerImage6" className="logo" actions={this.props.actions} saveAction={this.props.actions.updateUiPreset}/>
          </div>
        </div>
      </div>
    )
  }
}
