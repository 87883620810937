/* eslint-disable global-require */
import ReactOnRails from 'react-on-rails';

import { Application as Stimulus } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import * as ActiveStorage from 'activestorage';
import setupCSRFToken from '../setupCSRFToken';

import CustomerNameEditor from '../bundles/Envio/components/AdminPanel/Bookings/CustomerNameEditor';
import CustomerEmailEditor from '../bundles/Envio/components/AdminPanel/Bookings/CustomerEmailEditor';
import CustomerPhoneEditor from '../bundles/Envio/components/AdminPanel/Bookings/CustomerPhoneEditor';
import DeliveryAddressEditor from '../bundles/Envio/components/AdminPanel/Bookings/DeliveryAddressEditor';
import PeriodEditor from '../bundles/Envio/components/AdminPanel/Bookings/PeriodEditor';
import TrackingEditor from '../bundles/Envio/components/AdminPanel/Bookings/TrackingEditor';
import StateDropdown from '../bundles/Envio/components/AdminPanel/Bookings/StateDropdown';
import StateButton from '../bundles/Envio/components/AdminPanel/Bookings/StateButton';
import Checkbox from '../bundles/Envio/components/AdminPanel/Bookings/Checkbox';
import CustomisationWizard from '../bundles/Envio/components/AdminPanel/Customisations/CustomisationWizard';
import ProgramCustomisationApp from '../bundles/Envio/startup/ProgramCustomisationApp';
import UploaderFormWrapper from '../bundles/Envio/components/AdminPanel/UploaderFormWrapper';
import ColorPickerFormWrapper from '../bundles/Envio/components/AdminPanel/ColorPickerFormWrapper';
import Uploader from '../bundles/Envio/components/AdminPanel/Uploader';
import PurchasingWizard from '../bundles/Envio/components/AdminPanel/Purchasings/PurchasingWizard';
import ProgramPurchasingApp from '../bundles/Envio/startup/ProgramPurchasingApp';
import Thumbnail from '../bundles/Envio/components/AdminPanel/Thumbnail';
import Loader from '../bundles/Envio/components/AdminPanel/Loader';
import { InputLinkedMultiDatesPicker } from '../bundles/Envio/components/AdminPanel/MultiDatesPicker';
import { ViewDataStockLevelsTable } from '../bundles/Envio/components/AdminPanel/InventoryStockLevelsTable';

import AdminStore from '../bundles/Envio/store/AdminStore';

const AdminPanel = {};
AdminPanel.Bookings = {};

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
  require('@formatjs/intl-pluralrules/dist/locale-data/uk');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/uk');
}

ReactOnRails.register({
  'AdminPanel.Customisations.CustomisationWizard': CustomisationWizard,
  'AdminPanel.Bookings.CustomerNameEditor': CustomerNameEditor,
  'AdminPanel.Bookings.CustomerEmailEditor': CustomerEmailEditor,
  'AdminPanel.Bookings.CustomerPhoneEditor': CustomerPhoneEditor,
  'AdminPanel.Bookings.DeliveryAddressEditor': DeliveryAddressEditor,
  'AdminPanel.Bookings.PeriodEditor': PeriodEditor,
  'AdminPanel.Bookings.TrackingEditor': TrackingEditor,
  'AdminPanel.Bookings.StateDropdown': StateDropdown,
  'AdminPanel.Bookings.StateButton': StateButton,
  'AdminPanel.Bookings.Checkbox': Checkbox,
  'AdminPanel.UploaderFormWrapper': UploaderFormWrapper,
  'AdminPanel.ColorPickerFormWrapper': ColorPickerFormWrapper,
  'AdminPanel.Uploader': Uploader,
  ProgramCustomisationApp,
  ProgramPurchasingApp,
  'AdminPanel.Purchasings.PurchasingWizard': PurchasingWizard,
  'AdminPanel.Thumbnail': Thumbnail,
  'AdminPanel.Loader': Loader,
  InputLinkedMultiDatesPicker,
  ViewDataStockLevelsTable,
});

ReactOnRails.registerStore({
  AdminStore,
});

ActiveStorage.start();

const stim = Stimulus.start();
stim.load(
  definitionsFromContext(
    require.context('../bundles/Envio/controllers', true, /\.js$/),
  ),
);

window.stimulus = stim;

setupCSRFToken();
