import { ADMIN_PROGRAM_PURCHASE_UPDATE } from '../../../constants/admin/program/purchaseConstants';
import { ADMIN_PROGRAM_PURCHASE_UI_PRESET_UPDATE } from '../../../constants/admin/program/purchaseConstants';
import { ADMIN_ACTIVE_STORAGE_PURCHASE_ATTACHMENT_DELETE } from '../../../constants/admin/program/purchaseConstants';

import { mapStateToProps as mapProgramStateToProps }  from '../../../containers/ProgramPurchasingContainer';

const purchaseUiPresetReducer = (state = {}, action) => {
  state.lastAction = action;

  switch (action.type) {
    case ADMIN_PROGRAM_PURCHASE_UI_PRESET_UPDATE:
      return updatePurchaseUiPreset(state, action);
    case ADMIN_PROGRAM_PURCHASE_UPDATE:
      return updateProgramPurchase(state, action);
    case ADMIN_ACTIVE_STORAGE_PURCHASE_ATTACHMENT_DELETE:
      return purgeAttachment(state, action);
    default:
      return state;
    }
};

const updatePurchaseUiPreset = (state = {}, action) => {
  var newState = {...state}
  Object.assign(newState.preset, action.payload)

  return newState;
}

const updateProgramPurchase = (state = {}, action) => {
  var newState = {...state}
  return newState;
}

const purgeAttachment = (state = {}, action) => {
  var newState = {...state}
  newState.preset[action.payload.config.params.name].previewUrl = ""

  return newState
}

export default purchaseUiPresetReducer;
