import PropTypes from 'prop-types';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import GeneralAppearance from './GeneralAppearance';
import LandingPage from './LandingPage';
import BookingPopUp from './BookingPopUp';
import StickyMenu from './StickyMenu';

export default class CustomisationWizard extends React.Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = {};
  }

  render() {
    return (
      <div className="customisation-container">
        <StickyMenu {...this.props}/>
        <div className="card card-body">
          <div className="row">
            <div className="col-12">
              <Tabs>
                <TabList className="nav nav-pills flex-column flex-sm-row">
                  <Tab className="btn btn-link nav-link mt-0">General Appearance</Tab>
                  <Tab className="btn btn-link nav-link mt-0">Landing Page</Tab>
                  <Tab className="btn btn-link nav-link mt-0">Booking Pop-Up</Tab>
                </TabList>
                <TabPanel>
                  <GeneralAppearance {...this.props} />
                </TabPanel>
                <TabPanel>
                  <LandingPage {...this.props} />
                </TabPanel>
                <TabPanel>
                  <BookingPopUp {...this.props} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }

}
