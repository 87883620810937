import PropTypes from 'prop-types';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ChromePicker } from 'react-color';
import reactCSS from 'reactcss'

import ColorPicker from '../ColorPicker';
import ColorOption from './ColorOption';

export default class GeneralAppearance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const styles = reactCSS({
      'default': {
        color: {
          backgroundColor: this.props.presets.current().primaryColor,
          borderColor: this.props.presets.current().primaryColor,
          color: this.props.presets.current().primaryButtonTextOption == "light" ? "white" : "black"
        },
      },
    });
    return (
     <div>
       <h5 className="mt-5">
         <strong>General Appearance</strong>
       </h5>
       <div className="row customisation-container__section">
         <div className="col-sm-3">
           <p className="section__subtitle">Primary Colour</p>
           <p className="small text-secondary">This will be used throught the booking experience</p>
         </div>
         <div className="col-sm-3">
           <ColorPicker preset={this.props.presets.current()} actions={this.props.actions}/>
         </div>
       </div>
       <div className="row customisation-container__section">
         <div className="col-sm-3">
           <p className="section__subtitle">Primary Button Text Colour</p>
           <p className="small text-secondary">Choose between light and dark text to go with your buttons</p>
         </div>
         <div className="col-sm-3">
           <ColorOption
             preset={this.props.presets.current()}
             option={this.props.presets.current().primaryButtonTextOption}
             colors={["light", "dark"]}
             name='primaryButtonTextOption'
             actions={this.props.actions} />
         </div>
       </div>
       <div className="row customisation-container__section">
         <div className="col-sm-3 offset-md-3">
           <div className="card w-75">
             <div className="card-body">
               <div className="btn btn-primary mt-0 w-100" style={styles.color}>
                 <strong>CONTINUE</strong>
               </div>
             </div>
           </div>
           <p className="small text-secondary small">How your button will look</p>
         </div>
       </div>
     </div>
   );
  }
}
