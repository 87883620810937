import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['providerSelector', 'envioWrapper', 'wrapper'];

  connect() {
    this.update();
  }

  update() {
    this.resetDisplay();

    var element = this.wrapperTargets.find(
      target =>
        target.dataset.name == this.providerSelectorTarget.value.toLowerCase(),
    );

    if (element) {
      element.style.display = 'block';
    }
  }

  resetDisplay() {
    for (const target of this.wrapperTargets) {
      target.style.display = 'none';
    }
  }
}
