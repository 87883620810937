import { Controller } from 'stimulus';
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = ['averageAge', 'genderSplitMale', 'genderSplitFemale'];

  get averageAge() {
    return this.data.get('average-age');
  }
  set averageAge(value) {
    this.data.set('average-age', JSON.stringify(value));
  }
  get genderSplitMale() {
    return parseInt(this.data.get('gender-split-male') * 100);
  }
  get genderSplitFemale() {
    return parseInt(this.data.get('gender-split-female') * 100);
  }

  initialize() {}

  connect() {
    const options = {
      duration: 2.5,
      decimalPlaces: 0,
      suffix: '%',
    };

    new CountUp(this.averageAgeTarget, parseInt(this.averageAge)).start();
    new CountUp(
      this.genderSplitMaleTarget,
      this.genderSplitMale,
      options,
    ).start();
    new CountUp(
      this.genderSplitFemaleTarget,
      this.genderSplitFemale,
      options,
    ).start();
  }
}
