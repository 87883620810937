import { combineReducers } from 'redux';
import Cookie from 'js-cookie';

import { ADMIN_PROGRAM_UI_PRESET_UPDATE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_PUBLISH } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_SWITCH } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_CREATE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_CLONE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_PROGRAM_UI_PRESET_DELETE } from '../../../constants/admin/program/uiPresetConstants';
import { ADMIN_ACTIVE_STORAGE_ATTACHMENT_DELETE } from '../../../constants/admin/program/uiPresetConstants';

import { decoratePresets as mapProgramStateToProps } from '../../../containers/ProgramCustomisationContainer';

const uiPresetReducer = (state = {}, action) => {
  state.lastAction = action;

  switch (action.type) {
    case ADMIN_PROGRAM_UI_PRESET_UPDATE:
      return processUiPreset(state, action);
    case ADMIN_PROGRAM_UI_PRESET_PUBLISH:
      return publishUiPreset(state, action);
    case ADMIN_PROGRAM_UI_PRESET_SWITCH:
      return switchUiPreset(state, action);
    case ADMIN_PROGRAM_UI_PRESET_CREATE:
      return createUiPreset(state, action);
    case ADMIN_PROGRAM_UI_PRESET_DELETE:
      return deleteUiPreset(state, action);
    case ADMIN_ACTIVE_STORAGE_ATTACHMENT_DELETE:
      return purgeAttachment(state, action);
    default:
      return state;
  }
};

const processUiPreset = (state = {}, action) => {
  var newState = mapProgramStateToProps(state)

  if ("texts" in action.payload) {
    Object.assign(newState.presets.current().texts, action.payload.texts)
  } else {
    Object.assign(newState.presets.current(), action.payload)
  }

  Cookie.set('last_opened_ui_preset_id', newState.currentPresetId, { path: '' })

  return newState;
}

const publishUiPreset = (state = {}, action) => {
  var newState = mapProgramStateToProps(state)

  newState.presetsList.forEach(function (p) {
    p.id == action.payload ? p.published = true : p.published = false
  })

  return newState;
}

const switchUiPreset = (state = {}, action) => {
  var newState = mapProgramStateToProps(state)

  newState.currentPresetId = action.payload

  Cookie.set('last_opened_ui_preset_id', newState.currentPresetId, { path: '' })

  return newState;
}

const createUiPreset = (state = {}, action) => {
  var newState = { ...state }

  newState.currentPresetId = action.payload.id
  newState.presetsList.push(action.payload)

  Cookie.set('last_opened_ui_preset_id', newState.currentPresetId, { path: '' })

  return newState
}

const deleteUiPreset = (state = {}, action) => {
  var newState = { ...state }
  var id = action.payload.config.params.id

  for (var i = 0; i < newState.presetsList.length; i++) {
    if (newState.presetsList[i].id == id) {
      newState.presetsList.splice(i, 1)
    }
  }

  if (newState.currentPresetId == id) {
    newState.currentPresetId = newState.presetsList[newState.presetsList.length - 1].id
  }

  Cookie.set('last_opened_ui_preset_id', newState.currentPresetId, { path: '' })

  return newState
}

const purgeAttachment = (state = {}, action) => {
  var newState = { ...state }

  for (var i = 0; i < newState.presetsList.length; i++) {
    if (newState.presetsList[i].id == state.currentPresetId) {
      newState.presetsList[i][action.payload.config.params.name].previewUrl = ""
    }
  }

  return newState
}

export default uiPresetReducer;
