import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// import uiPresetReducer from '../reducers/admin/program/uiPresetReducer';
// import purchaseUiPresetReducer from '../reducers/admin/program/purchaseUiPresetReducer';
import rootReducer from '../reducers/admin/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (railsProps) => (
  createStore(
    rootReducer,
    railsProps,
    composeEnhancers(applyMiddleware(thunk))
  )
);

export default configureStore;
