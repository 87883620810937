import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["notificationEmails"];

  addEmail() {
    var lastEmailField;
    lastEmailField = $('input[name="brand[notification_emails][]"]:last-of-type').clone();
    lastEmailField.val("");

    $('input[name="brand[notification_emails][]"]:last-of-type').after(lastEmailField);
  }
  
}
