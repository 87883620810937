import React from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import useMultiDatesPicker from './useMultiDatesPicker';
import 'react-day-picker/lib/style.css';
import './MultiDatesPicker.css';

const MultiDatesPicker = ({ onChange, initialValue }) => {
  const {
    selectedDays,
    handleDayClick,
    removeRangeByIndex
  } = useMultiDatesPicker({ onChange, initialValue });

  return (
    <div className="MultiDatesPicker">
      <DayPicker
        selectedDays={selectedDays}
        firstDayOfWeek={1}
        onDayClick={(day, { selected }) =>
          handleDayClick(day, selected, selectedDays)
        }
      />
      <ul>
        {selectedDays.map((day, index) => {
          const localDay = moment(day).format('ll');
          return (
            <li key={localDay}>
              {localDay}
              <button
                type="button"
                aria-label="delete day"
                className="delete-mark"
                onClick={() => removeRangeByIndex(index, selectedDays)}
              >
                <i className="fa fa-times ml-3 d-inline-block" />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

MultiDatesPicker.propTypes = {
  /** Receiving dates table as a prop */
  onChange: PropTypes.func,
  initialValue: PropTypes.array
};

MultiDatesPicker.defaultProps = {
  onChange: () => null,
  initialValue: []
};

export default MultiDatesPicker;
