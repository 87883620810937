import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'formWrapper',
    'providerSelector',
    'template',
    'currentTemplate',
  ];

  connect() {
    this.update();
  }

  update() {
    let value = this.providerSelectorTarget.value;
    let shownForm = this.formWrapperTarget.querySelectorAll(`.form.shipping-provider-form[data-preset-provider="${value}"]`)[0]
    let template = shownForm.firstElementChild

    this.currentTemplateTarget.innerHTML = template.innerHTML
  }
}
