import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["radios", "customRadio", "envioRadio", "credentials"];

  connect() {
    if (this.customRadioTarget.firstElementChild.checked) {
      this.credentialsTarget.style.display = "block"
    }
  }

  addCredentials = (e) => {
    e.target.value === "1" ? this.credentialsTarget.style.display = "none" : this.credentialsTarget.style.display = "block"
  }
}
